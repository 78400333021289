<template>
  <div v-if="loading" class="loader">
    <Loader></Loader>
  </div>
  <div v-else-if="form && currentField" class="d-flex flex-column align-items-center w-100" id="Encuesta">
    <div v-if="!completedForm" class="d-flex flex-column align-items-center w-100">

      <!--            <span class="main-p smallest text-left font-weight-bold w-100 mb-2"> <RouterLink to="/interacciones" class="d-flex flex-nowrap mb-4 back-div" style="gap:10px"><img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-arrow" alt="" class="back-icon">  <div class="back-div">Regresar</div></RouterLink></span>-->
      <p class="main-p bigger mt-4 font-weight-bold">Trivia</p>
      <p class="main-p mb-0" style="">
        {{ currentFieldNumber }}
        <span>/</span>
        {{ form.config_json.fields.length }}
      </p>
      <p class="main-p text-center mb-4 mt-2" v-html="currentField.title"></p>

      <form class="w-100 d-flex flex-column align-items-start mb-4" style="max-width: 500px;">
        <button type="button" v-for="(option, index) in orderedAnswers" :key="index"
                class="btn-encuesta result mb-3"
                :class="{'active': selectedAnswer == option}"
                @click="selectedAnswer = option">
          <div class="d-flex align-items-center" style="gap:10px; position: relative">
            <span class="">{{["A", "B", "C", "D", "E"][index]}}</span>
            <strong class="px-4">{{option.title}}</strong>
          </div>
        </button>

      </form>

      <button class="main-btn mb-3"
              :class="{'disabled-btn': !selectedAnswer, 'main-btn': selectedAnswer}"
              :disabled="!selectedAnswer"
              @click="sendAnswer(selectedAnswer)">
        Enviar
      </button>


    </div>
    <div v-else class="d-flex flex-column align-items-center w-100">
      <!--            <span class="main-p smallest text-left font-weight-bold w-100 mb-2"> -->
      <!--                <RouterLink to="/interacciones" class="d-flex flex-nowrap mb-4 back-div" style="gap:10px">-->
      <!--                    <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-arrow" alt="" class="back-icon">-->
      <!--                    <div class="back-div">Regresar</div>-->
      <!--                </RouterLink>-->
      <!--            </span>-->

      <p class="main-p text-center mb-5 mt-0 biggest w-100" style="line-height: 20px">¡Muchas gracias <br/><br/> por contestar la trivia!


        <div v-if="results" class="w-100">
          <p class="main-p text-center mb-4 mt-4 bigger"><span>Tu resultado: </span>  {{correctAnswers}}<span>/</span>{{ totalQuestions }}</p>
          <div class="trivia-container">
            <div v-for="question in results.results" class="trivia-question">
              <div class="d-flex">
                <img v-if="question.answered.is_correct" src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-respuesta-correcta" width="26" height="26" style="margin-top: 0px;">
                <img v-else src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-respuesta-incorrecta" width="26" height="26" style="margin-top: 0px;">
                <p class="main-p  mt-1" style="line-height:18px;margin-left: 40px;font-size: 15px;font-weight: 500;text-align: left;" v-html="question.title"></p>
              </div>
              <div class="ms-4 d-flex" v-if="question.answered && question.answered.title && question.answered.is_correct">
                <p class="mb-0"><span class="ms-3 mt-0" style="color:#67eaae;line-height:18px;font-size: 14px;text-align:left">{{ question.answered.title }}</span></p>
              </div>
              <div class="ms-4 d-flex flex-column align-items-start" v-else-if="question.answered && question.answered.title ">
                <p class="mb-0"><span class="ms-3 mt-0" style="color:#d6040b;line-height:18px;font-size: 14px;text-align:left">{{ question.answered.title }}</span></p>
                <p class="mb-0" v-for="option in question.options"><span class="ms-3 mt-0" style="color:#67eaae;line-height:18px;font-size: 14px;text-align:left" v-if="option.is_correct == true">{{ option.title }}</span></p>
              </div>
            </div>
          </div>
        </div>
      </p>
      <button class="main-btn mb-3" @click="$router.push('/interacciones')">
        Regresar a las interacciones
      </button>
    </div>
    <p class="main-p smaller">Participar en la Trivia no incrementará tus probabilidades de ganar un premio en este Sorteo</p>
  </div>
  <div v-else class="loader">
    <Loader></Loader>
  </div>
</template>

<script>

import axios from 'axios';
import LobbyTabs from './LobbyTabs.vue'
import moment from 'moment-timezone';
import Loader from './Loader.vue';

export default {
  data() {
    return {
      loaded: false,
      form: null,
      currentFieldIndex: 0,
      currentAnswers: {},
      completedForm: false,
      results: null,
      loading: false,
      selectedAnswer: null,
      answeredForms: [],
    }
  },
  props: {
    me: Object
  },
  components: { LobbyTabs, Loader },
  mounted: function() {

  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    currentFieldNumber() {
      return this.currentFieldIndex + 1;
    },
    currentField() {
      return this.form.config_json.fields[this.currentFieldIndex] || this.form.config_json.fields[0];
    },
    localStorageKey() {
      return 'mechanics.quizzes.' + this.slug + '.' + this.me.id;
    },
    timeZone() {
      let timeZone =  this.me?.time_zone || 'Montaña'
      let timeZoneName = "America/Denver";

      if (timeZone === "Este") {
        timeZoneName = "America/New_York";
      } else if (timeZone === "Central") {
        timeZoneName = "America/Chicago";
      } else if (timeZone === "Montaña") {
        timeZoneName = "America/Denver";
      } else if (timeZone === "Pacífico") {
        timeZoneName = "America/Los_Angeles";
      } else if (timeZone === "Alaska") {
        timeZoneName = "America/Anchorage";
      } else if (timeZone === "Islas Hawaii") {
        timeZoneName = "Pacific/Honolulu";
      } else if (timeZone === "Atlántico") {
        timeZoneName = "America/Puerto_Rico";
      }
      return timeZoneName;
    },
    isFormCompleted() {
      if (this.form != null) {
        return (this.answeredForms.includes(this.form.id));
      }
      return false;
    },
    orderedAnswers() {
      let answers = this.currentField.answers;
      let orderedAnswers = answers.slice(); // Create a shallow copy of the original array
      orderedAnswers.sort(() => {
        return Math.random() - 0.5;
      });
      return orderedAnswers;
    },
    totalQuestions() {
      if (this.results && this.results.results) {
        return Object.keys(this.results.results).length;
      } else {
        return 0;
      }
    },
    correctAnswers() {
      let count = 0;
      if (this.results && this.results.results) {
        for (let option in this.results.results) {
          if (this.results.results.hasOwnProperty(option)) {
            if (this.results.results[option].answered.is_correct) {
              count = count + 1;
            }
          }
        }
      }
      return count;
    }
  },
  watch: {
    me: {
      handler: function (newItem) {

        if (newItem) {
          this.getSupportData();
        }
      },
      immediate: true
    },
    isFormCompleted(newVal) {
      if (newVal == true) {
        this.setFormAsCompleted();
      }
    }
  },
  methods: {
    getSupportData() {
      axios.post('api/v1/get-answered-polls_and_quizzes').then(response => {
        this.answeredForms = response.data
      }).catch(error => {
        if(error.response.status == 440) {
          this.$swal.fire({
            title: 'La sesión ha expirado',
            confirmButtonText: 'Regresar al inicio',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = '/';
            }
          })
        }
      });
      axios.get('api/v1/get-form?slug='+this.slug).then(response => {
        let form = response.data;
        // let currentDate = moment().tz(this.timeZone);
        // if(!currentDate.isBetween(form.available_from, form.available_to)) {
        //     this.$router.push('/interacciones')
        // }
        let filteredFields = form.config_json.fields.filter(field => field.data_type_enum === 40);
        form.config_json.fields = filteredFields;
        this.form = form;
        this.getCurrentFieldIndex();
        this.getCurrentAnswers();
      }).catch((error) => {
        console.log(error);
        this.$router.push('/interacciones')
      })
    },
    sendAnswer(option) {
      this.currentAnswers[this.currentField.slug] = option;
      let nextIndex = this.currentFieldIndex + 1
      if (nextIndex >= 0 && nextIndex < this.form.config_json.fields.length) {
        this.currentFieldIndex = nextIndex;
      } else {
        this.completeForm();
      }
      this.setCurrentFieldIndex(nextIndex);
      this.setCurrentAnswers(this.currentAnswers);
      this.selectedAnswer = null;

    },
    setFormAsCompleted(results = null) {
      if (!this.completedForm) {
        if (!results) {
          this.getResults();
        } else {
          this.results = results;
        }
        this.completedForm = true;
      }
    },
    getResults() {
      axios.get('api/v1/quiz-results/' + this.form.id, {
      }).then((response) => {
        this.results = response.data;
      })
    },
    completeForm() {
      this.loading = true;
      axios.post('api/v1/submit-quiz', {
        id: this.form.id,
        title: this.form.title,
        slug: this.slug,
        answers: this.currentAnswers,
        token_id: this.me.id
      }).then(response => {
        let results = response.data;
        this. setFormAsCompleted(results);
      }).catch(error => {
        if(error.response.status == 440) {
          this.$swal.fire({
            title: 'La sesión ha expirado',
            confirmButtonText: 'Regresar al inicio',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = '/';
            }
          })
        }
      }).finally(()=>{
        this.loading = false;
      })
    },
    getCurrentFieldIndex() {
      let currentFieldIndex = parseInt(window.localStorage.getItem(this.localStorageKey+'.fieldIndex')) || 0;
      if (this.form.config_json.fields.length && currentFieldIndex >= this.form.config_json.fields.length){
        this.setFormAsCompleted()
      } else {
        this.currentFieldIndex = currentFieldIndex;
      }
    },
    setCurrentFieldIndex(index) {
      window.localStorage.setItem(this.localStorageKey+'.fieldIndex', index);
    },
    getCurrentAnswers() {
      this.currentAnswers = JSON.parse(window.localStorage.getItem(this.localStorageKey+'.answers')) || {};
    },
    setCurrentAnswers(answers) {
      window.localStorage.setItem(this.localStorageKey+'.answers', JSON.stringify(answers));
    },
  }
};
</script>

<style lang="scss" scoped>
.trivia-container{
  position: relative;
  padding: 15px 10px;
  width: 100%;
  &::after {
    left: 0;
    top: 0;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.303);
    z-index: -1;
  }
}
.trivia-question{
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  &:last-of-type{
    margin-bottom: 0;
  }
  img{
    transform: translate(10%);
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
<template>
    <div v-if="me && contentPage && eventStatus === 'active'" class="d-flex flex-column align-items-center w-100" id="register">
        <div class="d-flex flex-column align-items-center w-100">
            <p class="main-p bigger mb-0 mt-4 font-weight-bold">
                Hola: <span>{{ capitalize(me.given_name) }}</span>
            </p>
            <p v-if="!formSent" class="main-p  mb-0 mt-4 font-weight-bold" 
                    v-html="contentPage.instance.header.content.values.title.value"> </p>
            <p v-else class="main-p bigger mb-0 mt-4 font-weight-bold"
                    v-html="contentPage.instance.completed_header.content.values.title.value"> </p>

            <div class="w-100">
                <div  v-if="!formSent" class="d-flex flex-column align-items-center w-100">
                    <DynamicForm
                        v-if="registerForm"
                        :data="registerForm"
                        @onSubmit="onSubmit"
                        @onCancel="onCancel"
                        class="w-100 d-flex align-items-center flex-column"
                        />
                        <p class="mt-3 main-p smaller">Si deseas iniciar sesión con un método diferente da <a href="#" class="text-decoration-underline a-link mb-3" @click="logOut()">click aquí</a></p>
                </div>
                <div v-else>
                    <p class="main-p smallest text-center mt-3 px-3 mb-4">
                    </p>
                    <RouterLink to="/lobby" class="d-flex flex-column align-items-center">
                        <button class="main-btn light mt-2 mb-3">
                        Continuar
                    </button>
                    </RouterLink>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DynamicForm from "@mints-components/cali-dynamic-form/App.vue";

import axios from 'axios';
export default {
  mixins: [],

  provide() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timeZoneName = "Montaña";

    if (timeZone === "America/New_York") {
        timeZoneName = "Este";
    } else if (timeZone === "America/Chicago") {
        timeZoneName = "Central";
    } else if (timeZone === "America/Denver") {
        timeZoneName = "Montaña";
    } else if (timeZone === "America/Los_Angeles") {
        timeZoneName = "Pacífico";
    } else if (timeZone === "America/Anchorage") {
        timeZoneName = "Alaska";
    } else if (timeZone === "Pacific/Honolulu") {
        timeZoneName = "Islas Hawaii";
    } else if (timeZone === "America/Puerto_Rico") {
        timeZoneName = "Atlántico";
    } 
    return {
      $contact: {
        time_zone_2024: timeZoneName
      }
    }
  },

  data() {
    return {
        registerForm: null,
        formSent: false,
        answers: null,
        loaded: false,
        contentPage: null,
    }
  },

  props: {
      me: Object,
      token: String,
      eventStatus: String
  },

  components: { DynamicForm },

  mounted: function() {
    this.getSupportData();
  },

  watch: {
        me: {
        handler: function (newItem) {
            
            if (newItem) {
                this.checkForData()
            }
        },
        immediate: true
        },
    },

  methods: {
    checkForData() {
        if (
            this.me.hasOwnProperty('phone') &&
            this.me.hasOwnProperty('zip_code') &&
            this.me.hasOwnProperty('time_zone') &&
            this.me.hasOwnProperty('confirm_age') &&
            this.me.hasOwnProperty('privacy_policy') &&
            this.me.hasOwnProperty('terms_and_conditions_accepted')
        ) {
            // Check for null, undefined or empty string values
            if (
                this.me.phone &&
                this.me.zip_code &&
                this.me.time_zone &&
                this.me.confirm_age &&
                this.me.privacy_policy &&
                this.me.terms_and_conditions_accepted 
            ) {
                this.$router.push('/lobby')
            } 
        } 
    },
    getSupportData() {
        axios.get('api/v1/get-content-page/additional-data').then(response => {
            this.contentPage = response.data.data
            this.registerForm = response.data.data.form.additional_data.content
        }).catch((error) => {
            console.log(error);
        })
    },
    onSubmit(answers, response) {
        if (response.error){
            this.sendAlert('error', 'Error en el servidor', response.error)
        } else {
            this.formSent = true;
            this.answers = answers;
            // get the contact data from the local storage
            let contact_id = localStorage.getItem('mints_contact_id')
            let localStorageKey = 'followers.contact.' + contact_id;
            let storedMe = JSON.parse(window.localStorage.getItem(localStorageKey)) || null;
            // if the contact data is not in the local storage get it from the session
            if (storedMe == null) {
                axios.post('api/v1/get-session',{id: id}, {headers: {'ContactToken': contact_token}}).then(response => {
                    if (response.data) {
                        let contactData = response.data;
                        let me = {
                            'id': contactData.id,
                            'mints_contact_id': contactData.token_id,
                            'given_name': contactData.given_name,
                            'last_name': contactData.last_name,
                            'phone': contactData.phone || null,
                            'email': contactData.email || null,
                            'zip_code': contact.zip_code_2024 || null,
                            'time_zone': contact.time_zone_2024 || null,
                            'confirm_age': contact.confirm_age_2024 || null,
                            'privacy_policy': contact.privacy_policy_2024 || null,
                            'terms_and_conditions_accepted': contact.terms_and_conditions_accepted_2024 || null,
                            'send_promotions_and_news': contact.send_promotions_and_news_2024 || null,
                            'full_name': contactData.full_name,
                            'display_name': contactData.display_name,
                        }
                        // update the contact data with the additional data
                        window.localStorage.setItem(localStorageKey, JSON.stringify(me));
                        this.$emit('updateMe', me);
                    }
                });
                
            } else {
                // update the contact data with the additional data
                storedMe.phone = answers.phone;
                storedMe.zip_code = answers.zip_code_2024;
                storedMe.time_zone = answers.time_zone_2024;
                storedMe.confirm_age = answers.confirm_age_2024;
                storedMe.privacy_policy = answers.privacy_policy_2024;
                storedMe.terms_and_conditions_accepted = answers.terms_and_conditions_accepted_2024;
                storedMe.send_promotions_and_news = answers.send_promotions_and_news_2024;
                window.localStorage.setItem(localStorageKey, JSON.stringify(storedMe));
                this.$emit('updateMe', storedMe);
            }
        }
    },

    onCancel() {
       console.log("Submission cancelled")
    },
    
    sendAlert(icon, title, text) {
        this.$swal.fire({
          icon: icon,
          title: title,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          text: text
        })
    },
    capitalize(text) {
        if (text) {
            text = text.toLowerCase();
            return text.split(' ').map(function(word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }).join(' ');
        }
        return text;
    },
    logOut() {
        axios.get('api/v1/logout').then(response => {
            this.$emit('clearSessionVars');
            window.location.href = '/login'
        })
    },
  }
};
</script>
